import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { useWindowSize } from "../../../hooks/useWindowSize";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal'
// import { DatePickerField } from "../../../shared/_helpers/datepicker";
import { NavLink, Link } from "react-router-dom"

import { AlertMsg } from "../../../shared/components/alert-msg"

import "react-datepicker/dist/react-datepicker.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { TableComponent } from "../../../shared/components/table"
import { InAppTemplate } from "../../../shared/templates/inapp";
import { storeActions } from "../../../redux/actions/in-app"
import SearchIco from "../../../assets/icons/search.svg"
import PP from "../../../assets/pp.svg"
import Caret1 from "../../../assets/icons/left-caret.svg"
import Caret2 from "../../../assets/icons/right-caret.svg"
import Chat from "../../../assets/chat.svg"
import CallNow from "../../../assets/call.svg"
import { LoadingItem } from "../../../shared/components/loading"
import { getDateFromISO, numberWithCommas } from "../../../shared/utils"

import ReactPaginate from 'react-paginate';

import "../index.scss"
import { number } from 'yup';

const getStoreOrders = async ({ pageProps, payload }: any) => {

    await pageProps.getStoreOrders(payload);
}

const getStoreOrderDetails = async ({ pageProps, payload }: any) => {

    await pageProps.getStoreOrderDetails(payload);
}
const changeOrderStatusAction = async ({ pageProps, payload, type, callback }: any) => {

    await pageProps.changeOrderStatus(payload, type, callback);
}
const EachProduct = ({ product, index, setProductList, productList, productListSummary, setProductListSummary }: any) => {
    const [qty, setQty] = useState<string>(product?.quantity || "")
    const [amount, setAmount] = useState<number>(product?.quantity * product?.price)


    const handleInputChange = (e: any, index: any, amountSum: any) => {

        const { name, value } = e.target;
        const list = [...productList];
        const listSummary = [...productListSummary];
        list[index]["actualQuantity"] = parseFloat(value);
        listSummary[index]["actualPrice"] = amountSum;


        setProductList(list)
        setProductListSummary(listSummary)
        setAmount(amountSum)

    };
    return (
        <div className="product_item">
            <div className="left_product_info">
                <div className="product_img">
                    <img src={product?.productUrl[0]} alt="" />
                </div>
                <div className="name_cats">
                    <div className="product_name">{product?.name} </div>
                    <div className="product_cats">
                        {product?.categories && Array.isArray(product?.categories) && product?.categories.length >= 1 && product?.categories.map((eachCat: any, index: any) => {
                            return (
                                <span key={index}>{eachCat}{index < product?.categories.length - 1 ? ", " : ""} </span>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="right_product_info">
                <div className="amount_qty_wrap">
                    <input type="text" value={numberWithCommas(qty)}
                        name={product?.productId}
                        onChange={(e: any) => {
                            let valueSet: any = "1"
                            // {
                            valueSet = numberWithCommas(e.target.value.trim())

                            setQty(valueSet)
                            if (e.target.value.trim().length >= 1) {
                                let amountSum = parseFloat(valueSet.trim().replace(/,/g, '')) * product?.price;
                                // setAmount(amountSum)
                                handleInputChange(e, index, amountSum)
                            } else {
                                // setAmount(0)
                                handleInputChange(e, index, 0)
                            }
                        }}
                    />
                    <div className="amount_total">  ${amount.toFixed(2)} </div>
                    {/* <div className="amount_total">  ${(Math.round(amount * 100) / 100).toFixed(2)} </div> */}
                </div>
                <div className="cost_per_unit">  {product?.price}per unit </div>
            </div>
        </div>
    )
}
const AllProducts = ({ pageProps, allProducts, productList, allProductSummary, initialTotalValue, chosenOrder, fetchPayload }: any) => {
    // const [activeProduct]
    const [productListArray, setProductList] = useState<any[]>([]);
    const [productListSummary, setProductListSummary] = useState<any[]>([]);
    const [totalValue, setTotal] = useState<any>(initialTotalValue);







    useEffect(() => {

        // let itemsSummary = allProducts?.vendorProducts.map((eachItem: any) => ({ actualPrice: eachItem?.quantity * eachItem?.price, productId: eachItem?.productId }))

        // let totalAmount = itemsSummary.reduce((accum: any, item: any) => accum + item.actualPrice, 0)
        // setTotal(totalAmount)



        let filteredItems = allProducts?.vendorProducts.map((eachItem: any) => ({ actualQuantity: eachItem?.quantity, productId: eachItem?.productId }))
        let itemsSummary = allProducts?.vendorProducts.map((eachItem: any) => ({ actualPrice: eachItem?.quantity * eachItem?.price, productId: eachItem?.productId }))
        setProductListSummary(itemsSummary)
        setProductList(filteredItems)

        // let totalAmount = itemsSummary.reduce((accum: any, item: any) => accum + item.actualPrice, 0)



    }, [])



    useEffect(() => {
        let totalAmount = productListSummary.reduce((accum, item) => accum + item.actualPrice, 0)
        setTotal(parseFloat(totalAmount).toFixed(2))




    }, [productListArray, productListSummary])


    const confirmOrder = () => {
        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        let payload = {
            orderNumber: chosenOrder?.request_data?.response?.orderNumber,
            vendorUserId: loggedAccountInfo?.userProfile?.id,
            vendorOrderProducts: productListArray
        }
        changeOrderStatusAction({
            pageProps,
            payload,
            type: "is_confirmed",
            callback: () => {
                getStoreOrders({
                    pageProps,
                    payload: fetchPayload
                })
            }
        })
    }


    return (
        <div className="products_wrap">
            {pageProps.changeOrderStatusRequest?.request_status !== "CHANGE_ORDER_STATUS_SUCCESS" &&
                <>
                    {
                        allProducts?.vendorProducts && productListArray.length >= 1 && productListSummary.length >= 1 && allProducts?.vendorProducts.map((eachProduct: any, index: any) => {
                            return (
                                <EachProduct
                                    key={index}
                                    setProductListSummary={setProductListSummary}
                                    productListSummary={productListSummary}
                                    productList={productListArray}
                                    setProductList={setProductList}
                                    index={index}
                                    product={eachProduct}
                                />

                            )
                        })
                    }
                    {totalValue &&
                        <div className="total_summary">
                            <div className="title_txt">Subtotal</div>
                            <div className="total_amount">${totalValue}</div>
                        </div>
                    }
                    {pageProps.changeOrderStatusRequest?.request_status === "CHANGE_ORDER_STATUS_FAILURE" &&
                        <AlertMsg type="error" message={pageProps.changeOrderStatusRequest?.request_data.error} />
                    }

                    <div className="confirm_cta">
                        <button className="btn"
                            onClick={confirmOrder}
                            disabled={pageProps.changeOrderStatusRequest.is_request_processing}
                        >
                            {pageProps.changeOrderStatusRequest.is_request_processing ? "Processing..." : "Confirm final price"}

                        </button>
                    </div>
                </>
            }
            {pageProps.changeOrderStatusRequest?.request_status === "CHANGE_ORDER_STATUS_SUCCESS" &&
                <AlertMsg type="success" message="Order has been successfully confirmed" />
            }
        </div>
    )
}

const LoadOrderInfo = ({ pageProps, showOrderInfo, setShowOrderInfo, chosenOrder, fetchPayload }: any) => {
    let orderInfo = pageProps.getStoreOrderDetailsRequest;
    const [productList, setProductList] = useState<any[]>([]);
    const [allProductSummary, setAllProductSummary] = useState<any[]>([]);
    const [initialTotalValue, setInitialTotal] = useState<any>();
    // const [allProductSummary, setAllProductSummary] = useState<any>();
    useEffect(() => {
        let payload = `OrderId=${chosenOrder?.id}`;


        getStoreOrderDetails({
            pageProps,
            payload
        })



    }, [])
    useEffect(() => {
        if (pageProps.getStoreOrderDetailsRequest.request_status === "GET_VENDOR_ORDER_DETAILS_SUCCESS") {
            let allItems = orderInfo?.request_data?.response?.vendorProducts;


            let filteredItems = orderInfo?.request_data?.response?.vendorProducts.map((eachItem: any) => ({ actualQuantity: eachItem?.quantity, productId: eachItem?.productId }))
            let itemsSummary = orderInfo?.request_data?.response?.vendorProducts.map((eachItem: any) => ({ actualPrice: eachItem?.quantity * eachItem?.price, productId: eachItem?.productId }))

            let totalAmount = itemsSummary.reduce((accum: any, item: any) => accum + item.actualPrice, 0)
            setInitialTotal(totalAmount)
            setProductList(filteredItems)
            setAllProductSummary(itemsSummary)
        }

    }, [pageProps.getStoreOrderDetailsRequest.request_status])

    return (
        <Modal className="order_info_wrap" show={showOrderInfo} onHide={() => {
            setShowOrderInfo(false)
        }}>
            <Modal.Header closeButton>
                <div className="heading_text">Confirm final price </div>
            </Modal.Header>
            <Modal.Body>

                {pageProps.getStoreOrderDetailsRequest.request_status === "GET_VENDOR_ORDER_DETAILS_PENDING" &&
                    <LoadingItem />
                }
                {pageProps.getStoreOrderDetailsRequest.request_status === "GET_VENDOR_ORDER_DETAILS_FAILURE" &&
                    <AlertMsg type="error" message={pageProps.getStoreOrderDetailsRequest?.request_data.error} />
                }
                {pageProps.getStoreOrderDetailsRequest.request_status === "GET_VENDOR_ORDER_DETAILS_SUCCESS" &&
                    <div className="order_details_wrap">
                        <div className="head_txt">This is a pre-authorisation process that ensures you&apos;re getting the full amount for the order</div>
                        <div className="all_products">
                            {orderInfo?.request_data?.response && productList.length >= 1 && allProductSummary.length >= 1 &&
                                <AllProducts
                                    pageProps={pageProps}
                                    chosenOrder={orderInfo}
                                    allProducts={orderInfo?.request_data?.response}
                                    productList={productList}
                                    allProductSummary={allProductSummary}
                                    initialTotalValue={initialTotalValue}
                                    fetchPayload={fetchPayload}
                                />

                            }
                            {/* <EachProduct
                        product={}
                        /> */}
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

const InAppContent = ({ pageProps }: any) => {
    const [startDate, onStartChange] = useState<any>();
    const [endDate, onEndChange] = useState<any>();
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);

    const [showOrderInfo, setShowOrderInfo] = useState(false)
    const [deliveryInfo, setDeliveryInfo] = useState<any>();
    const [chosenOrder, setChosenOrder] = useState<any>();
    const screenResolution: any = useWindowSize();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [fetchPayload, setFetchPayload] = useState<any>();


    // let page = 1,
    //     pageSize = 10;

    useEffect(() => {
        let payload = `Page=${pageNumber}&Size=${pageSize}`;
        setFetchPayload(payload)
        getStoreOrders({
            pageProps,
            payload
        })


    }, [])

    useEffect(() => {
        let payload = `Page=${pageNumber}&Size=${pageSize}`;
        setFetchPayload(payload)
        getStoreOrders({
            pageProps,
            payload
        })

    }, [pageNumber, pageSize])


    const ReportTable = ({ reportData }: any) => {
        let orderList: any[] = reportData?.results;
        if (orderList.length >= 1) {
            // orderList = orderList.filter(eachItem => eachItem.status === "Successful")
            return (
                <>
                    <thead>
                        <tr>
                            {/* <th>
                                <span>S/N</span>
                            </th> */}
                            <th>
                                <span>Order ID</span>
                            </th>
                            <th> <span>Order Date</span></th>
                            <th>Status</th>
                            <th>Subtotal</th>

                            <th> <span>Order Information</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderList.map((eachItem: any, index: any) => {
                                return (
                                    <tr key={index}>
                                        {/* <td>
                                            {index + 1}
                                        </td> */}
                                        <td>
                                            #{eachItem.number}
                                        </td>
                                        <td>
                                            <div className="date-item">{getDateFromISO({ date: eachItem.createdDate, returnTime: true }) || "N/A"}</div>
                                        </td>
                                        <td>
                                            {eachItem.status !== "Successful" && eachItem.status !== "Failed" && eachItem.status !== "Cancelled" && eachItem.status !== "SuccessfulPendingCapture" && <span className="order-status others">{eachItem.status}</span>}
                                            {eachItem.status == "SuccessfulPendingCapture" && <span className="order-status waiting">Pending</span>}
                                            {eachItem.status === "Failed" && <span className="order-status failed">Failed</span>}
                                            {eachItem.status === "Successful" && <span className="order-status done">Successful</span>}
                                            {eachItem.status === "Cancelled" && <span className="order-status failed">Cancelled</span>}
                                            {/* {eachItem.status === "Intent" && "Pending"}
                                            {eachItem.status === "Failed" && "Failed"}
                                            {eachItem.status === "Successful" && "Completed"} */}
                                        </td>
                                        <td>
                                            ${eachItem.subtotal}
                                        </td>
                                        <td>
                                            <div className="view-order">
                                                {eachItem.status === "SuccessfulPendingCapture" &&
                                                    <span className="confirm_cta" onClick={() => {
                                                        getStoreOrderDetails({
                                                            pageProps,
                                                            payload: "CLEAR"
                                                        })
                                                        changeOrderStatusAction({
                                                            pageProps,
                                                            payload: "CLEAR"
                                                        })
                                                        setChosenOrder(eachItem)
                                                        setShowOrderInfo(true)
                                                    }}>Confirm Order</span>
                                                }
                                                {eachItem.status !== "SuccessfulPendingCapture" &&
                                                    <Link to={`/app/my-orders/${eachItem.id}`}>See details</Link>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        {/* {
                        productsList.map((eachItem, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="product-info">
                                            <img src={eachItem.productImg} alt="" />
                                            <div className="prod-name">
                                                {eachItem.productName}
                                                <span>View Details</span>
                                            </div>
                                        </div>
                                    </td>

                                    <td><span className="unit-price">${eachItem.unitPrice}</span> </td>
                                    <td><span className="unit-price">{eachItem.instock}</span> </td>
                                    <td><span className="unit-price">$2.00</span> </td>
                                    <td><span className="unit-total">$31.19</span> </td>
                                    <td><span className="cate">{eachItem.category}</span> </td>
                                    <td><div className="date-item">{eachItem.dateCreated}</div> </td>
                                    <td><div className="date-item">Paid</div> </td>

                                    <td><div className="date-item">{eachItem.dateCreated}</div> </td>
                                    <td>
                                        <button onClick={() => { setShowDeliveryModal(true); setDeliveryInfo(eachItem) }}>Delivery Address</button>
                                    </td>
                                </tr>
                            )
                        })
                    } */}

                    </tbody>
                </>
            )
        } else {
            <div className="order-wrap">

                <div className="order-items-list">
                    <div className="all_products">
                        Order details unavailable
                    </div>
                </div>
            </div>
        }

        return <></>

    }

    const MobileDataGrid = ({ reportData }: any) => {

        let orderList: any[] = reportData?.results;
        if (orderList.length >= 1) {
            
            return (
                <div className="mobile-data-grid for_orders">
                    {(orderList.length >= 1) &&
                        <>
                            {orderList.map((eachItem: any, index: any) => {
                                return (
                                    <div className="each_data_item" key={index}>
                                        {/* <div className="top-tiny-txt">{getDateFromISO({ date: eachItem.createdDate, returnTime: true }) || "N/A"} </div> */}
                                        <div className="main-txt-head for_orders">
                                            <div className="order_id_action">
                                                <div className="id_txt">Order ID: {eachItem?.number}</div>
                                                <div className="order_cta">
                                                    {eachItem.status === "SuccessfulPendingCapture" &&
                                                        <span className="order_action confirm"
                                                            onClick={() => {
                                                                getStoreOrderDetails({
                                                                    pageProps,
                                                                    payload: "CLEAR"
                                                                })
                                                                changeOrderStatusAction({
                                                                    pageProps,
                                                                    payload: "CLEAR"
                                                                })
                                                                setChosenOrder(eachItem)
                                                                setShowOrderInfo(true)
                                                            }}
                                                        >Confirm Order</span>
                                                    }
                                                    {eachItem.status !== "SuccessfulPendingCapture" &&
                                                        <Link className="order_action got_to" to={`/app/my-orders/${eachItem.id}`}>See details</Link>
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                     
                                        <div className="each_mobile_prod_info">
                                            <div className="title-txt">Order date</div>
                                            <div className="availables"> {getDateFromISO({ date: eachItem.createdDate, returnTime: true }) || "N/A"}  </div>
                                        </div>
                                        <div className="each_mobile_prod_info">
                                            <div className="title-txt">Subtotal</div>
                                            <div className="availables"> ${eachItem.subtotal}  </div>
                                        </div>
                                        <div className="each_mobile_prod_info">
                                            <div className="title-txt">Order status</div>
                                            <div className="availables"> {eachItem.status!=="SuccessfulPendingCapture"? `${eachItem.status}`:"Pending"}  </div>
                                        </div>
                                       
                                    </div>
                                )
                            })}

                        </>

                    }

                </div >
            )
        } else {
            <div className="order-wrap">

                <div className="order-items-list">
                    <div className="all_products">
                        Order details unavailable
                    </div>
                </div>
            </div>
        }
        return <></>
    }

    const handlePageClick = (event: any) => {

        setPageNumber(event?.selected + 1)

    }

    return (
        <div className="inapp-content">
            <Modal className="address_modal_wrap" show={showDeliveryModal} onHide={() => setShowDeliveryModal(false)}>
                <Modal.Header closeButton>
                    {/* <div className="reason_heading">Reason for return </div> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="customer-info">
                        <div className="customer-avatar">
                            <img src={PP} alt="" />
                        </div>
                        <div className="customer-details">
                            <div className="customer-name">{deliveryInfo?.customerName}</div>
                            <div className="customer-email">{deliveryInfo?.customerEmail}</div>
                            <div className="inbox-cta">
                                <button type="button">
                                    <img src={Chat} alt="" />
                                    <span>Message Customer</span>
                                </button>
                            </div>
                            <div className="call-cta">
                                <button type="button">
                                    <img src={CallNow} alt="" /> <span>{deliveryInfo?.customerPhone}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="customer-address">
                        <h3>Delivery Address</h3>
                        {deliveryInfo?.customerAddress}
                    </div>

                </Modal.Body>
            </Modal>
            {showOrderInfo && chosenOrder &&
                <LoadOrderInfo
                    pageProps={pageProps}
                    showOrderInfo={showOrderInfo}
                    setShowOrderInfo={setShowOrderInfo}
                    chosenOrder={chosenOrder}
                    fetchPayload={fetchPayload}
                />
            }
            <div className="page-content lists-wrap cards with-heading order-wrap">
                <div className="orders-header">

                    {screenResolution?.width >= 769 && <h3>Orders</h3>}
                    {screenResolution?.width <= 768 && <h3>Order Information</h3>}
                    <div className="orders-txt">Manage all of your customer orders in one place.</div>
                </div>
                <div className="list-contanier">

                    <div className="list-content-wrap  products-list">
                        {pageProps.getStoreOrdersRequest.request_status === "GET_VENDOR_ORDERS_PENDING" &&
                            <LoadingItem />
                        }
                        {pageProps.getStoreOrdersRequest.request_status === "GET_VENDOR_ORDERS_SUCCESS" &&
                            <div>

                                <div className="all-items">

                                    {/* <div className="sub-headings">
                                        <div className="each-menu">
                                            <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/my-orders">
                                                Ordered Products
                                                <span className="underline"></span>
                                            </NavLink>
                                        </div>
                                        <div className="each-menu">
                                            <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/my-orders/cancelled">
                                                Cancelled Orders
                                            </NavLink>
                                        </div>
                                    </div> */}

                                    <div className="all-items-list">
                                        {screenResolution?.width >= 768 &&
                                            <TableComponent
                                                childComponent={<ReportTable reportData={pageProps.getStoreOrdersRequest.request_data?.response} />}
                                            />
                                        }
                                        {(screenResolution?.width <= 767 && pageProps?.getStoreOrdersRequest?.request_data?.response) &&
                                            <MobileDataGrid
                                                reportData={pageProps.getStoreOrdersRequest.request_data?.response}

                                            />
                                        }

                                        {(pageProps?.getStoreOrdersRequest?.request_data?.response?.totalNumberOfRecords > pageSize) &&
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                // initialPage={1}
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={4}
                                                forcePage={pageNumber - 1}
                                                // onClick={handleItemsClick}
                                                className="pagination_items"
                                                pageClassName="page_num"
                                                pageLinkClassName="page_link"
                                                activeClassName="active_page_link"
                                                previousClassName="previous_page_link"
                                                nextClassName="next_page_link"
                                                pageCount={pageProps?.getStoreOrdersRequest?.request_data?.response?.pageNumber}
                                                previousLabel="<"
                                                // @ts-ignore
                                                renderOnZeroPageCount={null}
                                            />
                                        }

                                        {/* <div className="pagination-wrap to-right">
                                            <div className="prev-page each-page">
                                                <img src={Caret1} alt="" />
                                            </div>
                                            <div className="prev-page each-page active">1</div>
                                            <div className=" each-page">2</div>
                                            <div className=" each-page">3</div>
                                            <div className=" each-page">4</div>
                                            <div className=" each-page">5</div>
                                            <div className="next-page each-page">
                                                <img src={Caret2} alt="" />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        }
                        {pageProps.getStoreOrdersRequest.request_status === "GET_VENDOR_ORDERS_FAILURE" &&
                            <AlertMsg type="error" message={pageProps.getStoreOrdersRequest?.request_data.error} />
                        }

                    </div>
                </div>
            </div>
        </div>
    )

}
const OrderList = (pageProps: any) => {
    const screenResolution: any = useWindowSize();
    return (
        <InAppTemplate isWide={screenResolution?.width <= 1024 ?true:false} pageHeadingTitle="My Orders" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}

const mapDispatchToProps = {
    getStoreOrders: storeActions.GetStoreOrders,
    getStoreOrderDetails: storeActions.GetStoreOrderDetails,
    changeOrderStatus: storeActions.ChangeOrderStatus,
};

const mapStateToProps = (state: any) => ({
    // return {
    getStoreOrdersRequest: state.allProductReducers.getStoreOrderReducer,
    getStoreOrderDetailsRequest: state.allProductReducers.getStoreOrderDetailsReducer,
    changeOrderStatusRequest: state.allProductReducers.changeOrderStatusReducer,
    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderList);