import bg1 from '../../../assets/bg1.webp'
import bg2 from '../../../assets/bg2.svg'
import bg3 from '../../../assets/bg3.svg'
import bg4 from '../../../assets/bg4.svg'
import { BsGlobe } from 'react-icons/bs'
import { LuWallet } from 'react-icons/lu'
import { HiArrowTrendingUp } from 'react-icons/hi2'
import { TbFolderBolt } from 'react-icons/tb'
import '../../../scss/cards.scss'
import person1 from '../../../assets/person1.webp'
import person2 from '../../../assets/person2.webp'
import person3 from '../../../assets/person3.webp'
import { useState } from 'react'
import { BiSolidQuoteAltLeft } from 'react-icons/bi'
import { questions } from '../../inapp/help-center/questions'
import { LiaAngleDownSolid } from 'react-icons/lia'
import Marquee from 'react-fast-marquee'


const comment = [
    {
        name: 'Tobi Lionel',
        data: {
            title: '“I experienced a seamless onboarding process that allowed me to set up my online store within minutes.”',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me. From the moment I joined, I experienced a seamless onboarding process that allowed me to set up my online store within minutes. The user-friendly interface and intuitive tools made store management a breeze, enabling me to focus more on what I do best: creating and selling my handmade jewelry.',
            commentdown: "But it doesn't stop there. MamiMarkets.com has truly opened doors for me to reach a vast customer base that I couldn't have accessed on my own. The platform's robust marketing features, such as product recommendations and targeted advertising, have significantly increased my brand visibility and attracted a steady stream of interested buyers. The enhanced market reach has allowed my small business to grow exponentially, and I've witnessed a remarkable increase in sales since joining the platform."
        }
    },
    {
        name: 'Lauren James',
        data: {
            title: '“I experienced a seamless onboarding process that allowed me to set up my online store within minutes.”',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me. From the moment I joined, I experienced a seamless onboarding process that allowed me to set up my online store within minutes. The user-friendly interface and intuitive tools made store management a breeze, enabling me to focus more on what I do best: creating and selling my handmade jewelry.',
        }
    },
    {
        name: 'William Andem',
        data: {
            title: '“I experienced a seamless onboarding process that allowed me to set up my online store within minutes.”',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me. From the moment I joined, I experienced a seamless onboarding process that allowed me to set up my online store within minutes. The user-friendly interface and intuitive tools made store management a breeze, enabling me to focus more on what I do best: creating and selling my handmade jewelry.',
            commentdown: "But it doesn't stop there. MamiMarkets.com has truly opened doors for me to reach a vast customer base that I couldn't have accessed on my own. The platform's robust marketing features, such as product recommendations and targeted advertising, have significantly increased my brand visibility and attracted a steady stream of interested buyers. The enhanced market reach has allowed my small business to grow exponentially, and I've witnessed a remarkable increase in sales since joining the platform."
        }
    },

]

export const Cards = () => {
    const [select, setSelect] = useState<number>(0);
    const [items, setItems] = useState(comment[0].data)
    const [item, setItem] = useState<Array<any>>(questions[0]?.data)
    const [show, setShow] = useState<number | null>(0)

    const handleSelect = (id: number) => {
        setSelect(id);

        setItems(comment[id]?.data)
    }
    const toggleAnswer = (index: number) => {
        if (show === index) {
            setShow(null)
        }
        else {
            setShow(index)
        }
    }

    const cards = [
        {
            bg: bg1,
            icon: <BsGlobe />,
            title: 'Market reach',
            text: `Access to a broader customer base`
        },
        {
            bg: bg2,
            icon: <LuWallet />,
            title: 'Cost-effective setup',
            text: 'Budget-friendly solution with value'
        },
        {
            bg: bg3,
            icon: <HiArrowTrendingUp />,
            title: 'Growth potential',
            text: 'Adapt to changing customer demands'
        },
        {
            bg: bg4,
            icon: <TbFolderBolt />,
            title: 'Store management',
            text: 'Efficiently run your online business operations'
        },

    ]
    const vendors = [
        {
            img: person1,
            name: 'Tobi Lionel',
            store: 'The Food Emporium',
            location: 'Markham, Ontario'
        },
        {
            img: person2,
            name: 'Lauren James',
            store: 'Savoury Pantry',
            location: 'Markham, Ontario'
        }, {
            img: person3,
            name: 'William Andem',
            store: 'Tasty Treats Market',
            location: 'Markham, Ontario'
        },
    ]
    return (
        <>
            <div className='cardContainer'>
                <div className='flex'>
                    <div>
                        <p className='benefit'>BENEFITS</p>
                        <h4 className='title'>Everything necessary to surpass business expectations</h4>
                        <h4 className='titles'>Everything necessary to surpass business expectations</h4>
                    </div>
                    <div>
                        <p className='subtext'>Simplify your operations, maximize sales, and expand your business horizons—all at your fingertips.</p>
                    </div>
                </div>
                <div className='cards'>
                    {
                        cards.map((elem, idx) => {
                            return (
                                <div style={{ background: `url(${elem.bg})`, backgroundPosition: 'center', backgroundSize: 'cover' }} className={`card ${idx === 0 && 'card0'}`} key={idx}>
                                    <div className='icon'>
                                        {elem.icon}
                                    </div>
                                    <h5 className='cardTitle'>{elem.title}</h5>
                                    <p className='cardText'>{elem.text}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='top'>
                    <h3 className='title'>See what our current vendors<br /> are saying</h3>
                    <h3 className='titles'>See what our current vendors<br /> are saying</h3>
                    <div className='rows'>
                        <div className='users'>
                            {
                                vendors.map((vendor, idx) => {
                                    return (
                                        <div key={idx} className={select === idx ? 'user' : 'user2'} onClick={() => handleSelect(idx)}>
                                            <img src={vendor.img} alt='' />
                                            <div className='details'>
                                                <h6 className='name'>{vendor.name}</h6>
                                                <p className='store'>{vendor.store}</p>
                                                <p className='store'>{vendor.location}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className='users-mobile'>
                            <Marquee>
                                {
                                    vendors.map((vendor, idx) => {
                                        return (
                                            <div key={idx} className={select === idx ? 'user' : 'user2'} onClick={() => handleSelect(idx)}>
                                                <img src={vendor.img} alt='' />
                                                <div className='details'>
                                                    <h6 className='name'>{vendor.name}</h6>
                                                    <p className='store'>{vendor.store}</p>
                                                    <p className='store'>{vendor.location}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Marquee>
                        </div>

                        <div className='quote'>
                            <BiSolidQuoteAltLeft size={80} color='#E7FAE8' />
                        </div>
                        <div className='comments'>
                            <div>
                                <h3 className='commentTitle'>{items.title}</h3>
                                <p className='commentText'>{items.commenttop}</p>
                                <p className='commentText'>{items.commentdown}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className='title'>Frequently Asked<br />Questions</h3>
                    <h3 className='titles'>Frequently Asked Questions</h3>
                    <div className='top2'>
                        <div className='left'>
                            <p className='subtext2'> We understand that learning new technologies and navigating an
                                ecommerce platform can sometimes be complex. That's why we've created this FAQs
                                section to provide you with helpful information and address common concerns.
                            </p>
                            <button className='contact'>Contact us</button>
                        </div>
                        <div className='right'>
                            {item?.map((question, index) => (
                                <>
                                    <div className="question">
                                        <div className="flexes" key={index}>
                                            <h3 className="question">{question.question}</h3>
                                            <div onClick={() => toggleAnswer(index)}>
                                                <LiaAngleDownSolid size={14} />
                                            </div>
                                        </div>
                                        {
                                            show === index && <p className="answer">{question.answer}</p>
                                        }
                                    </div>
                                    <div className="bottom" />
                                </>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <div className='mapBg'>
                <p className='join'>JOIN US TODAY</p>
                <h3 className='mapTitle'>Discover why 100+ businesses are partnered with MamiMarkets </h3>
                <p className='mapText'>From our proven track record to our tailored solutions, industry insights,
                    extensive network, and unwavering commitment
                    to customer satisfaction, MamiMarkets is the trusted partner
                    you need to accelerate your business growth and achieve remarkable results.
                </p>
                <button className='start'>Get started</button>
            </div>
        </>
    )
}
