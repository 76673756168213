import { Link } from "react-router-dom";
import logo from "../../../assets/logo2.png"
import '../../../scss/landing.scss'
import mumbo from '../../../assets/icons/mumbo.svg'
import rubik from '../../../assets/icons/rubik.svg'
import toast from '../../../assets/icons/toast.svg'
import gusto from '../../../assets/icons/gusto.svg'
import pendo from '../../../assets/icons/pendo.svg'
import synk from '../../../assets/icons/synk.svg'
import { FiBarChart2 } from 'react-icons/fi'
import { BsCart3 } from "react-icons/bs"
import graph from '../../../assets/graph.webp'
import order from '../../../assets/order.webp'
import imgStack from '../../../assets/img-stack.svg'
import imgStack2 from '../../../assets/img-stack2.svg'
import withdraw from '../../../assets/withdraw.webp'
import { IoDocumentOutline, IoCheckmarkDoneOutline } from 'react-icons/io5'
import { HiOutlineClipboardList } from 'react-icons/hi'
import { PiUsersThreeBold } from 'react-icons/pi'
import card1 from '../../../assets/card1.svg'
import card2 from '../../../assets/card2.svg'
import card3 from '../../../assets/card3.svg'
import card4 from '../../../assets/card4.svg'
import { Cards } from "./cards";
import PersonalInfo from "./form/personal-info";
import Marquee from "react-fast-marquee";


const images = [
    {
        img: mumbo
    },
    {
        img: rubik
    },
    {
        img: toast
    },
    {
        img: gusto
    },
    {
        img: pendo
    },
    {
        img: synk
    },

]

const cards = [
    {
        icon: <IoDocumentOutline />,
        name: 'Submit information',
        text: 'Fill out your physical store information such as its location, operating hours, and contact information as well as your personal details',
        bg: card1
    },
    {
        icon: <IoCheckmarkDoneOutline />,
        name: 'Verification',
        text: 'Fill out your physical store information such as its location, operating hours, and contact information as well as your personal details',
        bg: card2
    },
    {
        icon: <HiOutlineClipboardList />,
        name: 'Product inventory',
        text: 'Fill out your physical store information such as its location, operating hours, and contact information as well as your personal details',
        bg: card3
    },
    {
        icon: <PiUsersThreeBold />,
        name: 'Sell to customers',
        text: 'Fill out your physical store information such as its location, operating hours, and contact information as well as your personal details',
        bg: card4
    },

]
const LandingPage = () => {


    return (
        <div className="containers">
            <div className="landing-header">
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
                <div className="links">
                    <Link to={'/login'} className="login">Log in </Link>
                    <a href='#form' className="get-button">Get started</a>
                </div>
            </div>
            <div className="hero-bg">
                <div>
                    <h5 className="title">
                        Expand your customer base with MamiMarkets
                    </h5>
                    <PersonalInfo />
                </div>
                <div className='sideImg'>
                    <img src={imgStack} className="img1" />
                    <img src={imgStack2} className="img2" />
                </div>

            </div>
            <div className="second-container">
                <div className="right">
                    <h6 className="growth">
                        Businesses in Canada use MamiMarkets for growth
                    </h6>

                </div>

                <Marquee>
                    <div className="icons">
                        {
                            images.map((image, idx) => {
                                return (
                                    <div className="icon" key={idx}>
                                        <img src={image.img} alt="" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Marquee>
            </div>
            <div className="third-container"id="form" >
                <h4 className="scale">Scale your wholesale and retail outlets</h4>
                <div className="flex2">
                    <div className="row2">
                        <div className="roww">
                            <FiBarChart2 color="#00A85B" />
                            <p className="data">DATA VISUALISATION</p>
                        </div>
                        <h4 className="key">Key Performance Indicators</h4>
                        <p className="texts">We believe that success is measured by
                            data-driven insights. That's why we empower you with a comprehensive
                            set of Key Performance Indicators to supercharge your ecommerce journey.
                        </p>
                        <p className="texts">
                            By monitoring these indicators, you gain
                            invaluable insights into your business's health and make
                            informed decisions to drive growth and maximize profitability.
                        </p>
                    </div>
                    <div className="graph">
                        <img src={graph} alt="" />
                    </div>
                </div>
            </div>
            <div className="third-container" style={{ marginTop: '-3rem' }}>

                <div className="flex">
                    <div className="graph">
                        <img src={order} alt="" />
                    </div>
                    <div className="row2">
                        <div className="roww">
                            <BsCart3 color="#00A85B" />
                            <p className="data">ORDER COLLATION</p>
                        </div>
                        <h4 className="key">Streamline your business with seamless<br /> order management</h4>
                        <h4 className="key2">Streamline your business with seamless order management</h4>
                        <p className="texts">
                            At MamiMarkets, we know the importance of efficient
                            order management to the success of your business.
                            That's why we've developed a robust order management system
                            that empowers you to take control of your orders,
                            streamline your processes, and deliver exceptional customer experiences.
                        </p>
                        <p className="texts">
                            Our platform automates the entire order processing workflow,
                            from order placement to fulfillment, ensuring accuracy and saving you valuable time.
                        </p>
                    </div>

                </div>
            </div>
            <div className="third-container" style={{ marginTop: '-3rem' }}>

                <div className="flex2">
                    <div className="row2">
                        <div className="roww">
                            <BsCart3 color="#00A85B" />
                            <p className="data">WITHDRAWALS</p>
                        </div>
                        <h4 className="key">Automated withdrawal process</h4>
                        <p className="texts">
                            With a seamless solution designed to simplify your payment collection process and ensure you
                            receive your payments directly from your customers, without any hidden costs.
                        </p>
                        <p className="texts">
                            So, say goodbye to the hassle of manual invoicing, chasing late payments,
                            and dealing with complex payment gateways. With our automated system,
                            you can streamline your cash flow and focus on what truly matters: growing your business.
                        </p>
                    </div>
                    <div className="graph">
                        <img src={withdraw} alt="" />
                    </div>
                </div>
            </div>
            <div className="graphbg">
                <div className="first">
                    <h3>Starting out as a vendor on MamiMarkets is incredibly easy, and straightforward</h3>
                    <p>Discover the simplicity of selling on <br /> MamiMarkets as a vendor</p>
                </div>
              <div className="first2">
              <div className="grid">
                    {
                        cards.map((elem, idx) => {
                            return (
                                <div className={`cardBg ${idx%2 !== 0 && 'cardBg2'}`} style={{ background: `url(${elem.bg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                    <div className="icon">{elem.icon}</div>
                                    <h4 className="cardTitle">{elem.name}</h4>
                                    <p className="cardtext">{elem.text}</p>
                                </div>
                            )
                        })
                    }
                </div>
                {/* <div className="grid2">
                    {
                        cards.slice(2, 4).map((elem, idx) => {
                            return (
                                <div className='cardBg' style={{ background: `url(${elem.bg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                    <div className="icon">{elem.icon}</div>
                                    <h4 className="cardTitle">{elem.name}</h4>
                                    <p className="cardtext">{elem.text}</p>
                                </div>
                            )
                        })
                    }
                </div> */}
              </div>
            </div>
            <Cards />
        </div>
    )
}
export default LandingPage;